@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  color: black;
  font-family: 'Outfit', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}


button{
  cursor: pointer;
  border: none;
  background: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


b{
  color: #0074FF;
}

/* font weight */
.font-light{
  font-weight: 300;
}
.font-normal{
  font-weight: 400;
}

.font-medium{
  font-weight: 500;
}
.font-semibold{
  font-weight: 600;
}
.font-bold{
  font-weight: 700;
}

/* font size */
.h1{
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 81px;
  text-align: center;
}
.h2 {
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 60px;
line-height: 1.2;
}
.h3 {
  font-size: 2.5rem;
  line-height: 1.167;
}
.h4 {
  
  font-size: 2.125rem;
  line-height: 1.235;
  font-weight: 600;
  letter-spacing: 0.00735em;
}
.h5 {
  
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
}
.h6 {
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.p {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.0075em;
}
.sp{
  font-size: 0.85rem;
}

.ssp{
  font-size: 0.65rem;
}


.text-grey{
  color: #636363;
}

.uppercase{
  text-transform: uppercase;
}

.caption{
  font-size: 0.75rem;
}

.rounded{
  border-radius: 50%;
}

.rounded-sm{
  border-radius: 0.25rem;
}


.rounded-md{
  border-radius: 0.375rem;
}

.rounded-lg{
  border-radius: 0.5rem;
}

.rounded-xl{
  border-radius: 0.75rem;
}

.rounded-2xl{
  border-radius: 1rem;
}

.rounded-3xl{
  border-radius: 1.5rem;
}
.rounded-full{
  border-radius: 9999px;
}



.pointer{
  cursor: pointer;
}
.flex {
  display: flex;
}
.scroll{
  overflow: scroll;
}
.grid{
  display: grid;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.fixed{
  position: fixed;
}

.row{
  flex-direction: row;
}

.column{
  flex-direction: column;
}

.jc{
  justify-content: center;
}
.ac{
  align-items: center;
}
.afs{
  align-items: flex-start;
}
.jsb{
  justify-content: space-between;
}
.flex-1 {
  flex: 1 1;
}


.w-full{
  width: 100%;
}
.w-fit{
  width: -moz-fit-content;
  width: fit-content;
}

.w-max{
  width: max-content;
  min-width: max-content;

}

.w-min{
  width: min-content;
}



.h-full{
  height:100%;
}

.gap-2{
  gap: 0.5rem;
}

.gap-4{
  gap:1rem;
}
.gap-6{
  gap:1.5rem;
}


.gap-8{
  gap:2rem;
}


.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.center {
  display:flex;
  align-items: center;
  justify-content: center;
}

.transition {
  transition: all 0.3s ease;
}

.ma {
  margin: auto;
}
.m0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}
.m-8 {
  margin: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}


.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}


.p-3 {
  padding: 0.75rem !important;
}


.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.preline{
  white-space: pre-line;
}


.animate-spin{
  animation: spin 1s linear infinite;
}
.animate-ping{
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-pulse{
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-bounce{
  animation: bounce 1s infinite;
}




/* animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}


@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}


/* .sectionPadding{
  max-width: 1400px;
  margin:auto;
}

@media (min-width: 1400px) {
  .sectionPadding{
    padding: 1rem;
  }
} */


.dashedBorder{
  border: 2px dashed #406AFF !important;
}


.shadow-md{
  box-shadow: 0px 20px 30px rgba(14, 36, 58, 0.1);
}

@media (min-width: 768px) {

  .md-h1{
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 81px;
    text-align: center;
  }
  .md-h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  line-height: 1.2;
  }
  .md-h3 {
    font-size: 3rem;
    line-height: 1.167;
  }
  .md-h4 {
    
    font-size: 2.125rem;
    line-height: 1.235;
    font-weight: 600;
    letter-spacing: 0.00735em;
  }
  .md-h5 {
    
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
  }
  .md-h6 {
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }


  .md-p {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.0075em;
  }
  .md-sp{
    font-size: 0.85rem;
  }
  
  .md-ssp{
    font-size: 0.65rem;
  }


}
/* 
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

